<template>
  <div id="app">
      <div v-if="$TwiLoading" class="loading">
        <img src="./assets/images/logo-icon.png" alt="" />
      </div>

      <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: 'App',
  beforeMount() {
    this.$TwiLoading = true;
  },
  mounted() {
    this.$TwiLoading= false
    //this.getIpData();
  },
  methods: {
    async getIpData(){
      await this.axios.get('https://api.ipify.org?format=json').then(async response => {
        await this.axios.get(`https://ipwhois.app/json/${response.data.ip}`).then((res) => {
          //await axios.post('http://localhost:3000', response.data).then((res) =>{
            console.log(res.data);
          //}).catch(() => {/*console.clear();*/});
        }).catch(() => {
          //console.clear();
        }); 
      }).catch(() => {
         //console.clear();
      }); 
    },
  }
}
</script>