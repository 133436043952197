import Vue from 'vue'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router';
import store from './store';

import VueSlickCarousel from 'vue-slick-carousel'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)

Vue.component("vue-slick-carousel", VueSlickCarousel);
Vue.config.productionTip = false;
Vue.prototype.$TwiLoading = true;

axios.defaults.baseURL = "http://localhost:4000/api";


new Vue({
  router,
  vuetify,
  store,
  created() {
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      const userData = JSON.parse(userInfo);
      this.$store.commit('setUserData', userData)
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')