export default [
    {
        path: '/admin',
        name: 'DashboardPage',
        component: () => import('./../views/dashboard/DashboardPage')
    },

    {
        path: '/dashboard/users',
        name: 'DashboardUsers',
        component: () => import('./../views/dashboard/users/DashboardUsers')
    },
    {
        path: '/dashboard/users/create',
        name: 'CreateUser',
        component: () => import('./../views/dashboard/users/CreateUser')
    },
    {
        path: '/dashboard/users/:id',
        name: 'EditUser',
        component: () => import('./../views/dashboard/users/EditUser')
    },

    {
        path: '/dashboard/products',
        name: 'DashboardProducts',
        component: () => import('./../views/dashboard/products/DashboardProduct')
    },
    {
        path: '/dashboard/products/create',
        name: 'CreateProduct',
        component: () => import('./../views/dashboard/products/CreateProduct')
    },
    {
        path: '/dashboard/products/:id',
        name: 'EditProduct',
        component: () => import('./../views/dashboard/products/EditProduct')
    },

    {
        path: '/dashboard/coupons',
        name: 'DashboardCoupons',
        component: () => import('./../views/dashboard/coupons/DashboardCoupon')
    },
    {
        path: '/dashboard/coupons/create',
        name: 'CreateCoupon',
        component: () => import('./../views/dashboard/coupons/CreateCoupon')
    },
    {
        path: '/dashboard/coupons/:id',
        name: 'EditCoupon',
        component: () => import('./../views/dashboard/coupons/EditCoupon')
    },
];