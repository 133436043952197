import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import router from './router';

Vue.use(Vuex);

const state = {
    sidebarShow: window.innerWidth > 778 ? true : false,
    user: null,
};

const mutations = {
    toggleSidebar(state) {
        state.sidebarShow = state.sidebarShow == true ? false : true
    },
    set(state, [variable, value]) {
        state[variable] = value
    },
    setUserData(state, userData) {
        state.user = userData;
        localStorage.setItem('user', JSON.stringify(userData));
        axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },
    clearUserData() {
        axios.post('logout').finally(() => {
            localStorage.removeItem('user');
            const pathName = router.currentRoute.matched[0]?.name
            if (pathName == 'Admin') {
                router.push('/dashboard/login')
            } else {
                router.push('/login')
            }
        });


    },
};
const actions = {
    login({ commit }, credentials) {
        commit('setUserData', credentials)
        router.push('/dashboard')
    },
    logout({ commit }) {
        commit('clearUserData')
    },
};
const getters = {
    isLogged: state => !!state.user,
    user(state) {
        return state.user;
    },
};

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})
