import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

// WebSite Components
const TheWebsiteContainer = () => import('./views/website/TheWebsiteContainer')
import WebsiteRoutes from './router/website';

// auth pages
const LoginPage = () => import('./views/auth/LoginPage');
const LoginDashboard = () => import('./views/auth/LoginDashboard');

// Dashboard Components
const TheContainer = () => import('./views/dashboard/TheContainer')
import DashboardRoutes from './router/dashboard';


const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')
    const pathName = to.matched[0]?.name

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        if (pathName == 'Admin') {
            next('/dashboard/login')
        } else {
            next('/login')
        }
        return
    }

    if ((to.name === 'LoginPage' && loggedIn) || (to.name === 'LoginDashboard' && loggedIn)) {
        const userData = JSON.parse(loggedIn);
        if (userData.role && userData.role === 'admin') {
            next('/dashboard');
        } else {
            next('/');
        }
        return
    }
    next();
})

export default router

function configRoutes() {
    return [
        {
            path: '/dashboard',
            redirect: '/admin',
            name: 'Admin',
            meta: { auth: true },
            component: TheContainer,
            children: [
                ...DashboardRoutes
            ]
        },
        {
            path: '/admin/login',
            redirect: '/dashboard/login',
            name: 'AdminLogin',
            meta: { auth: false },
            component: LoginDashboard,
            children: [
                { path: '/dashboard/login', name: 'LoginDashboard', component: LoginDashboard }
            ]
        },
        {
            path: '/',
            name: 'Website',
            redirect: '/',
            component: TheWebsiteContainer,
            meta: { auth: false },
            children: [
                ...WebsiteRoutes,

                { path: '/login', name: 'LoginPage', component: LoginPage }
            ]
        }
    ]
}