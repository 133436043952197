export default [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('./../views/website/HomePage'),
        meta: { auth: false },
    },
    {
        path: '/products',
        name: 'ProductsPage',
        component: () => import('./../views/website/ProductsPage'),
        meta: { auth: false },
    }
];