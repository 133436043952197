import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

const opts = {
    theme: {
        dark: false,
        default: 'light',
        disable: false,
        options: {
            cspNonce: undefined,
            customProperties: undefined,
            minifyTheme: undefined,
            themeCache: undefined,
        },
        themes: {
            light: {
                primary: '#0096C7',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#46BBB1',
                success: '#4CAF50',
                warning: '#EE4F12',
            },
            dark: {
                primary: '#0096C7',
                secondary: '#424242',
                accent: '#FF4081',
                error: '#FF5252',
                info: '#46BBB1',
                success: '#4CAF50',
                warning: '#EE4F12',
            },
        },
    },
    options: {
        customProperties: true
    },
    icons: {
        iconfont: "mdi"
    }
};

export default new Vuetify(opts)